import {createWebHistory, createRouter} from "vue-router";

// import VueAxios from 'vue-axios'

import HomeComponent from "@/pages/HomeComponent";

import axios from 'axios'

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeComponent,
        meta: {
            title: ""
        }
    },
    {
        path: "/compare-rate",
        name: "CompareRate",
        component: () => import('../pages/CompareRateComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/unsubscribe",
        name: "UnSubscribe",
        component: () => import('../pages/UnsubscribeComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/your-quote",
        name: "YourQuote",
        component: () => import('../pages/Step3Component.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/your_quote",
        name: "YourQuoteA",
        component: () => import('../pages/YourQuoteComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/booking",
        name: "ApiBooking",
        component: () => import('../pages/ApiBookingComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/your-detail",
        name: "YourDetail",
        component: () => import('../pages/Step4Component.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/about-us",
        name: "AboutUs",
        component: () => import('../pages/AboutUsComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/airport/:slug",
        name: "Airport",
        component: () => import('../pages/AirportComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: () => import('../pages/ContactUsComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/faqs",
        name: "FAQs",
        component: () => import('../pages/FAQComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/my-booking",
        name: "MyBooking",
        component: () => import('../pages/MyBookingComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/my-booking/detail",
        name: "MyBookingDetail",
        component: () => import('../pages/DetailPage.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/reviews",
        name: "Reviews",
        component: () => import('../pages/ReviewComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/terms-and-condition",
        name: "TermsAndCondition",
        component: () => import('../pages/TermsAndConditionComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: () => import('../pages/PrivacyPolicyComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/cookie-policy",
        name: "CookiePolicy",
        component: () => import('../pages/CookiesPolicyComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/payment/success",
        name: "Success",
        component: () => import('../pages/SuccessComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/payment/success-api",
        name: "SuccessApi",
        component: () => import('../pages/SuccessComponentApi.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/payment/cancel",
        name: "Cancel",
        component: () => import('../pages/CancelComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: "/company/terms-and-condition",
        name: "CompanyTerms",
        component: () => import('../pages/CompanyTermsComponent.vue') ,
        meta: {
            title: ""
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../pages/NotFoundComponent.vue') ,
        meta: {
            title: ""
        }
    },


];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
});

router.beforeEach((to, from, next) => {
    if (to != from) {
        document.title = `${to.meta.title}`;

        let routeName = to.name;
        let baseName = null;
        if (routeName == "Home") {
            baseName = "index"
        } else if (routeName == "CompareRate") {
            baseName = "compare-rate"
        } else if (routeName == "UnSubscribe") {
            baseName = null
        } else if (routeName == "YourQuote") {
            baseName = "your-quote"
        } else if (routeName == "ApiBooking") {
            baseName = null
        } else if (routeName == "YourDetail") {
            baseName = "your-detail"
        } else if (routeName == "AboutUs") {
            baseName = "about-us";
        } else if (routeName == "Airport") {
            baseName = null
        } else if (routeName == "ContactUs") {
            baseName = "contactus"
        } else if (routeName == "FAQs") {
            baseName = "faq"
        } else if (routeName == "MyBooking") {
            baseName = "my-bookings"
        } else if (routeName == "MyBookingDetail") {
            baseName = null
        } else if (routeName == "Reviews") {
            baseName = null
        } else if (routeName == "TermsAndCondition") {
            baseName = "terms"
        } else if (routeName == "PrivacyPolicy") {
            baseName = "Privacy Policy"
        } else if (routeName == "Success") {
            baseName = "success"
        } else if (routeName == "Cancel") {
            baseName = null
        } else if (routeName == "CompanyTerms") {
            baseName = null
        } else if (routeName == "NotFound") {
            baseName = null
        } else {
            baseName = null;
        }


        if (baseName != null) {
            let formData = {'base_name': baseName};
            axios.post('https://app.compareparkingprices.co.uk/api/index.php/home/getSEOInfo', formData)
                .then(function (response) {
                    if (response.status) {
                        document.title = response.data.data.seo_title;
                        document.querySelector('meta[name="title"]').content = response.data.data.seo_title
                        document.querySelector('meta[name="description"]').content = response.data.data.seo_description
                        document.querySelector('meta[name="keywords"]').content = response.data.data.seo_keyword

                    }else{
                        document.querySelector('meta[name="title"]').content = ``
                        document.querySelector('meta[name="description"]').content = ``
                        document.querySelector('meta[name="keywords"]').content = ``
                    }
                })
        }

    }
    next();
})

export default router;