<template>
  <!-- BANNER WRAP AREA -->
  <div class="banner_area">
    <div class="container">
      <div class="row title">
        <div class="col-md-12">
          <h2>Get the best UK airport Parking deals – every time!</h2>
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="left_form">
              <div class="form_wrpa">
                <div class="row">
                  <div class="col-md-12">
                    <div class="loop_fields">
                      <label for="airport_select"  style="position: absolute;z-index: 1;">Select Airport</label>
                      <select id="airport_select" ref="airport_select" class="form-control" v-model="formSearch.airport_id" v-on:change="airportChange($event)" aria-placeholder="Select" placeholder="Select" style="z-index: 2 !important;padding-top: 25px;position: relative;">
                        <option disabled selected hidden value="">Select</option>
                        <option v-for="airport in airports" v-bind:key="airport.id+','+airport.code" :value="airport.id+','+airport.code" :data-code="airport.discount_code">{{ airport.name }}</option>
                      </select>
                      <img src="images/map.svg" alt="map">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-7 col-7" >
                    <div class="loop_fields" v-on:click="checkDate($event,$refs.date_off)" data-open="0">
                      <label >Drop Off Date</label>
                      <Datepicker ref="date_off" :format="format" v-model="formSearch.drop_off_date" @update:modelValue="handleDate" auto-apply :min-date="new Date()" :enable-time-picker="false" :clearable="false"></Datepicker>
                    </div>
                  </div>
                  <div class="col-md-5 col-5">
                    <div class="loop_fields" v-on:click="$refs.off_time.focus()">
                      <label style="position: absolute;z-index: 1;">Drop Off Time</label>
                      <select ref="off_time" class="form-control" v-model="formSearch.drop_off_time" style="z-index: 2 !important;padding-top: 25px;position: relative;">
                        <template v-for="(n,i) in 24" v-bind:key="n">
                          <option :value="i+':00'">{{ i + ':00' }}</option>
                          <option :value="i+':15'">{{ i + ':15' }}</option>
                          <option :value="i+':30'">{{ i + ':30' }}</option>
                          <option :value="i+':45'">{{ i + ':45' }}</option>
                        </template>

                      </select>
                      <img src="/images/clock.svg" alt="image-clock">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-7 col-7">
                    <div class="loop_fields" v-on:click="checkDate($event,$refs.up_date)" data-open="0">
                      <label>Pick Up Date</label>
                      <Datepicker ref="up_date" :format="format" v-model="formSearch.pick_up_date" auto-apply :min-date="formSearch.drop_off_date" :enable-time-picker="false" :clearable="false"></Datepicker>
                    </div>
                  </div>
                  <div class="col-md-5 col-5">
                    <div class="loop_fields" v-on:click="$refs.up_time.focus()">
                      <label style="position: absolute;z-index: 1;">Pick Up Time</label>
                      <select ref="up_time" class="form-control" v-model="formSearch.pick_up_time" style="z-index: 2 !important;padding-top: 25px;position: relative;">
                        <template v-for="(n,i) in 24" v-bind:key="n">
                          <option :value="i+':00'">{{ i + ':00' }}</option>
                          <option :value="i+':15'">{{ i + ':15' }}</option>
                          <option :value="i+':30'">{{ i + ':30' }}</option>
                          <option :value="i+':45'">{{ i + ':45' }}</option>
                        </template>

                      </select>
                      <img src="images/clock.svg" alt="image-clock">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="loop_fields" v-on:click="$refs.discount_c.focus()">
                      <label>Discount Code</label>
                      <input ref="discount_c" class="form-control" placeholder="Enter Discount Code" type="text" v-model="formSearch.discount_code"  v-on:keypress="changeDiscount()" v-on:change="changeDiscount()">
                      <img src="images/discount.svg" alt="image-discount">
                    </div>
                  </div>
                </div>
                <div v-show="is_extra === 1" class="row">

                  <div class="col-md-12">
                    <div class="loop_fields form_nav">
                      <div class="extra_wrap">
                        <label>Extras</label>
                        <ul class="nav">
                          <li><span><i class="fa-solid fa-square-check"></i></span>{{ sms_confirmation_tag }}
                            <button type="button" class="dstooltip" data-bs-toggle="tooltip" data-bs-target=".tooltip" :title="sms_confirmation_tooltip"><i class="fa-solid fa-circle-info"></i></button>
                          </li>
                          <li><span><i class="fa-solid fa-square-check"></i></span>{{ cancellation_cover_tag }}
                            <button type="button" class="dstooltip" data-bs-toggle="tooltip" data-bs-target=".tooltip" :title="cancellation_cover_tooltip"><i class="fa-solid fa-circle-info"></i></button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <input class="btn btn_blue" type="submit" name="" value="COMPARE PRICES">
                </div>
              </div>
            </div>
          </div>
          <div class="right_img col-lg-8 col-md-6">
            <div class="banner_bottom_text">
              <span>Get a great price on airport parking,<br>No matter where you’re headed!</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- BANNER WRAP AREA -->


  <!-- CARIOSAL WRAP AREA -->
  <div class="cariosal_wrap">
    <div class="container">
      <div class="row title">
        <h2>Our Best Airport Parking Deals</h2>
        <p></p>
      </div>
    </div>
    <div class="container">
      <carousel :items-to-show="4.5" :breakpoints="breakpoints">
        <slide v-for="company in companies" v-bind:key="company.company_id">
          <div class="p-1 _wpa">
            <div class="cario_loop">
              <div class="heading">
                <h2><router-link style="color: #fff;text-decoration: none" :to="'/airport/'+company.airport_name.toLowerCase()" >{{ company.airport_name }}</router-link></h2>
              </div>
              <div class="img">
                <img style="aspect-ratio: 139/55;cursor: pointer" v-on:click="handleBookingSubmit('form_'+company.company_id)" :src="imageURL+company.image" alt="image-com-name">
              </div>
              <div class="content">
                <h3 style="cursor: pointer" v-on:click="handleBookingSubmit('form_'+company.company_id)">{{ company.company_name }}</h3>
                <p>{{ company.millage_description }} miles from the terminal</p>
                <span>£ {{ company.total_amount }}</span>
              </div>
              <div class="bnt_wrap">
                <form v-bind:ref="'form_'+company.company_id"
                      v-on:submit.prevent="handleBookingSubmit('form_'+company.company_id)" method="post">
                  <input type="hidden" name="hiddenStarting_date_time" :value="company.drop_off_date">
                  <input type="hidden" name="hidden_Dep_time" :value="company.drop_off_time">
                  <input type="hidden" name="hidden_end_date" :value="company.pick_up_date">
                  <input type="hidden" name="hidden_arrival_time" :value="company.pick_up_time">
                  <input type="hidden" name="starting_date_time" :value="company.drop_off_date">
                  <input type="hidden" name="departure_time" :value="company.drop_off_time">
                  <input type="hidden" name="ending_date_time" :value="company.pick_up_date">
                  <input type="hidden" name="arrival_time" :value="company.pick_up_time">
                  <input type="hidden" name="discount_amount" :value="company.discount_amount">
                  <input type="hidden" name="discount_code" :value="company.discount_code">
                  <input type="hidden" name="airport" :value="company.airport_id">
                  <input type="hidden" name="airport_name" :value="company.airport_name">
                  <input type="hidden" name="plan_id" :value="company.plan_id">
                  <input type="hidden" name="company_id" :value="company.company_id">
                  <input type="hidden" name="amount" :value="company.total_amount">
                  <input type="hidden" name="company" :value="company.company_name">
                  <input type="hidden" name="day_diff" :value="company.day_difference">
                  <input type="hidden" name="image" :value="company.is_api ? company.image_url : imageURL+company.image">
                  <input type="hidden" value name="terms">
                  <input type="hidden" name="is_api" :value="company.is_api">
                  <input type="hidden" name="quote_id" :value="company.quote_id">
                  <input type="hidden" name="parking_type" :value="company.parking_type">
                  <input type="hidden" name="sms_confirmation" :value="formSearch.sms_confirmation">
                  <input type="hidden" name="cancellation_cover" :value="formSearch.cancellation_cover">
                  <input type="hidden" name="via" :value="'Home'">
                  <button type="submit" value="BOOK NOW" class="btn btn_blue">Book Now</button>
                </form>
              </div>
            </div>
          </div>
        </slide>

        <template #addons>
          <navigation/>
          <pagination/>
        </template>
      </carousel>
    </div>
    <div class="container">
      <div class="row title">
        <p>*These prices are for 8 days parking ( {{ drop_off_date }} - {{ pick_up_date }} )</p>
      </div>
    </div>
  </div>
  <!-- CARIOSAL WRAP AREA -->

  <!-- ABOUT WRAP AREA -->
  <div class="about_wrap">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="left_img">
            <img src="images/aboutus.png" style="aspect-ratio: auto 1/1" alt="image-aboutus">
          </div>
        </div>
        <div class="col-md-6">
          <div class="right_content">
            <h2>Compare Parking Prices UK is here to make finding the best airport parking deals even easier.</h2>

            <p>We function as an intermediary between customers looking for cheap airport parking and independent airport parking companies across UK airports to ensure you get the best possible deal on your car parking needs.</p>

            <p>As booking agents, we're here to help; allow us to ensure your airport parking experience is as seamless and affordable as possible.</p>

            <p>With Compare Parking Prices, you're guaranteed to find unbeatable prices. Whether you're off on business or jet-setting with the family, we make sure you get great value for money! We empower you to compare your parking options and choose the best one; whether traveling from Manchester, Luton, or Heathrow, we have you covered.</p>

            <p>We offer a multitude of services, ensuring whatever your needs are, we can help. From long stays to Meet and Greet service, we source the best prices on the widest range of parking services. So, no matter your trip, take the hassle out of airport parking with Compare Parking Prices.</p>

            <p>For seamless travel, consider our efficient parking services at all major airport across the UK, including Heathrow Airport, Birmingham Airport, and Stansted Airport, ensuring hassle-free journeys for all travelers.</p>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ABOUT WRAP AREA -->


  <!-- TYPE WRAP AREA -->
  <div class="type_wrap">
    <div class="container">
      <div class="row title">
        <div class="col-md-12">
          <h2>Types of Parking</h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row wp">
        <div class="col-md-4">
          <div class="type_loop">
            <img style="aspect-ratio: auto 65/58" src="images/icone4.png" alt="image-icone4">
            <h2>MEET & GREET</h2>
            <p>The quickest and most convenient way of parking is meet and greet parking. Call your car parking company 20-30 minutes before reaching the terminal. The driver will meet you at the terminal drop-off point, and you will go away. On your return, call the car parking company, and they will pick you up in your car outside the terminal entrance.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="type_loop">
            <img style="aspect-ratio: auto 109/58" src="images/icone5.png" alt="image-icone5">
            <h2>PARK & RIDE</h2>
            <p>If you are looking for a cost-effective parking solution then park & ride is the right answer, as it has a 10 to 15-minute transfer by shuttle. These facilities offer personalized customer service. The shuttles normally run from the parking yard very frequently and on demand.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="type_loop">
            <img style="aspect-ratio: auto 62/58" src="images/icone6.png" alt="image-icone6">
            <h2>ONSITE</h2>
            <p>The airport itself offers parking. In most cases, there is a long-stay car parking option, which involves a free shuttle van transfer to and from the airport, and short-stay parking, normally within walking distance of the airport terminal. We offer onsite parking facilities in all major airports across UK. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- TYPE WRAP AREA -->

  <!-- NEWSLETTER AREA	 -->
  <div class="newsletter">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left_content">
            <h2>Grab airport parking deals up to 75% off for your next holiday by joining our mailing list!</h2>
          </div>
        </div>
        <div class="col-md-12">
          <div class="subscribe-section">
            <form>
              <input class="form-control" type="text" placeholder="Your Name(Only Letters)" name="name" v-model="form.name">
              <input class="form-control" type="text" placeholder="youremail@host.com" name="email" v-model="form.email">
              <input class="btn" type="button" name="" value="JOIN" v-on:click="subscribeNewsletter()">
            </form>
            <p>You can unsubscribe at any time. For more information, please see our <a href="/privacy-policy">Privacy Policy.</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- NEWSLETTER AREA	 -->

  <div ref="loader" class="loader-section" >
    <div class="loader"></div>
  </div>


</template>

<script>
import moment from 'moment'
import Tooltip from 'bootstrap/js/src/tooltip.js'
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';

export default {
  name: "HomeComponent",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  beforeCreate() {
    if (localStorage.getItem('formSearch')) {
      localStorage.removeItem('formSearch')
    }


  },
  data() {
    return {
      drop_off_date: '',
      pick_up_date: '',
      airports: {},
      form: {
        'email': '',
        'name': ''
      },
      formSearch: {
        'airport_id': '',
        'drop_off_date': new Date(),
        'drop_off_time': "12:00",
        'pick_up_date': new Date(),
        'pick_up_time': "12:00",
        'phone_no': '',
        'discount_code': '',
        'sms_confirmation': 1,
        'cancellation_cover': 1,
      },
      cheapForm: {
        'airport_id': 4,
        'drop_off_date': '',
        'drop_off_time': "0:00",
        'pick_up_date': '',
        'pick_up_time': "0:00",
        'phone_no': '',
        'discount_code': '',
      },
      formBookNow: {
        "hiddenStarting_date_time": "",
        "hidden_Dep_time": "",
        "hidden_end_date": "",
        "hidden_arrival_time": "",
        "starting_date_time": "",
        "departure_time": "",
        "ending_date_time": "",
        "arrival_time": "",
        "discount_amount": "",
        "discount_code": "",
        "day_difference": "",
        "airport": "",
        "airport_name": "",
        "plan_id": "",
        "company_id": "",
        "amount": "",
        "company": "",
        "day_diff": "",
        "image": "",
        "terms": "",
        "is_api": "",
        "quote_id": "",
        "parking_type": "",
        "sms_confirmation": "",
        "cancellation_cover": "",
        'expiry_time': moment(new Date).add(30, 'minutes').format('X'),
      },
      companies: [],
      breakpoints: {
        // 700px and up
        100: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        768: {
          itemsToShow: 2.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },
      is_extra: 0,
      sms_confirmation_tag: "SMS Confirmation",
      cancellation_cover_tag: "Cancellation Cover",
      sms_confirmation_tooltip: "",
      cancellation_cover_tooltip: "",
      styleTagSMS: "font-weight:normal",
      styleTagCancellation: "font-weight:normal",
      is_market_discount:0,
      is_md:0, // second check market discount
      is_modified:0,
    }
  },
  methods: {
    getAirports() {
      this.axios.get(this.apiURL + '/home/getAirports').then((response) => {
        this.airports = response.data.data;
      });

    },
    checkDate(evt,ev){
      if(evt.currentTarget.dataset.open == 1){
        ev.closeMenu();
        evt.currentTarget.dataset.open = 0
      }else{
        ev.openMenu()
        evt.currentTarget.dataset.open = 1
      }
    },
    handleDate() {
      let newDate = new Date(this.formSearch.drop_off_date);
      newDate.setDate(this.formSearch.drop_off_date.getDate() + 7)
      this.formSearch.pick_up_date = newDate;
    },
    format(date) {
      // const day = date.getDate();
      // const month = date.getMonth() + 1;
      // const year = date.getFullYear();
      return moment(date).format('ddd DD MMMM YYYY');
    },
    formatSliderDate(date) {
      // const day = date.getDate();
      // const month = date.getMonth() + 1;
      // const year = date.getFullYear();
      return moment(date).format('DD MMM');
    },
    subscribeNewsletter() {
      this.axios.post(this.apiURL + '/home/subscribe', this.form, {
        headers: {}
      })
          .then(function (response) {
            let res = response.data;
            if (res.status == 1) {
              /*toast({
                title: 'Email subscribed successfully',
                toast: true,
                position: "bottom",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  title: "blackToast-title",
                },
              });*/
              alert('Email subscribed successfully')

              this.form.email = '';
              this.form.name = '';
            } else {
              alert(res.message)
              /*toast({
                title: res.message,
                toast: true,
                position: "bottom",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  title: "blackToast-title",
                },
              });*/
            }

            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });


    },
    async handleSubmit() {

      if (this.$refs.airport_select.value) {
        localStorage.setItem('formSearch', JSON.stringify(this.formSearch))
        this.$router.push({
          name: 'CompareRate',
        });
      } else {
        alert('Please select an airport')
      }


    },
    handleBookingSubmit(reference) {
      const formBooking = this.$refs[reference];

      this.formBookNow.hiddenStarting_date_time = formBooking[0].elements.hiddenStarting_date_time.value;
      this.formBookNow.hidden_Dep_time = formBooking[0].elements.hidden_Dep_time.value;
      this.formBookNow.hidden_end_date = formBooking[0].elements.hidden_end_date.value;
      this.formBookNow.hidden_arrival_time = formBooking[0].elements.hidden_arrival_time.value;
      this.formBookNow.starting_date_time = formBooking[0].elements.starting_date_time.value;
      this.formBookNow.departure_time = formBooking[0].elements.departure_time.value;
      this.formBookNow.ending_date_time = formBooking[0].elements.ending_date_time.value;
      this.formBookNow.arrival_time = formBooking[0].elements.arrival_time.value;
      this.formBookNow.discount_amount = formBooking[0].elements.discount_amount.value;
      this.formBookNow.discount_code = formBooking[0].elements.discount_code.value;
      this.formBookNow.airport = formBooking[0].elements.airport.value;
      this.formBookNow.airport_name = formBooking[0].elements.airport_name.value;
      this.formBookNow.plan_id = formBooking[0].elements.plan_id.value;
      this.formBookNow.company_id = formBooking[0].elements.company_id.value;
      this.formBookNow.amount = formBooking[0].elements.amount.value;
      this.formBookNow.company = formBooking[0].elements.company.value;
      this.formBookNow.day_diff = formBooking[0].elements.day_diff.value;
      this.formBookNow.image = formBooking[0].elements.image.value;
      this.formBookNow.terms = formBooking[0].elements.terms.value;
      this.formBookNow.is_api = formBooking[0].elements.is_api.value;
      this.formBookNow.quote_id = formBooking[0].elements.quote_id.value;
      this.formBookNow.parking_type = formBooking[0].elements.parking_type.value;
      this.formBookNow.via = formBooking[0].elements.via.value;
      this.formBookNow.expiry_time = moment(new Date).add(30, 'minutes').format('X');


      localStorage.setItem('booking', JSON.stringify(this.formBookNow));
      console.log(`is_api = ${this.formBookNow.is_api}`)
      if(this.formBookNow.is_api == 1){
        this.$router.push({
          name: 'ApiBooking',
        });
      }else{
        this.$router.push({
          name: 'YourQuoteA',
        });
      }

    },

    getPrices() {
      this.axios.get(this.apiURL + '/booking/getBookingPrices').then((response) => {
        this.formSearch.sms_confirmation = response.data.data.sms_confirmation;
        this.formSearch.cancellation_cover = response.data.data.cancellation_cover;
        this.is_extra = response.data.data.is_extra
        this.sms_confirmation_tag = response.data.data.sms_label
        this.cancellation_cover_tag = response.data.data.cancellation_label
        this.sms_confirmation_tooltip = response.data.data.sms_tooltip
        this.cancellation_cover_tooltip = response.data.data.cancellation_tooltip
      });
    },
    async airportChange(ev) {
      console.log(ev)
      let discountCode = this.$refs.airport_select.options[this.$refs.airport_select.selectedIndex].dataset.code;
      // It reprocess the code and checks for marketing discount....
      await this.fetchMD(this.formSearch.discount_code)

      console.log(this.is_md);

      /*if (this.$route.query && this.$route.query.offer_id != undefined && this.is_market_discount == 1 && this.is_modified == 0) {
        this.formSearch.discount_code = this.$route.query.offer_id
      } */

      if(this.is_md){
        // it will not reflect the entry
      }else {
        if(discountCode != null){
          this.formSearch.discount_code = discountCode;
        }
      }
    },
    fetchMarketDiscount(){
      if (this.$route.query && this.$route.query.offer_id != undefined) {
        let formData = {
          'offer_id': this.$route.query.offer_id
        }

        this.axios.post(this.apiURL + '/home/checkMarketingDiscount', formData).then((response) => {
          this.is_market_discount = response.data.data
        })
      }
    },

    fetchMD(code){
      let formData = {
        'offer_id': code
      }

      this.axios.post(this.apiURL + '/home/checkMarketingDiscount', formData).then((response) => {
        this.is_md = response.data.data;
        return;
      })
    },
    changeDiscount(){
      this.fetchMD(this.formSearch.discount_code)
    }

  },
  beforeMount() {
    this.getPrices();
  },
  mounted() {

    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
    this.getAirports();
    this.handleDate();
    this.fetchMarketDiscount();

    var date = new Date();

    if (this.$route.query) {
      this.formSearch.discount_code = this.$route.query.offer_id
    }

    this.changeDiscount();

    let formData = {
      'airport_id': 4,
      'drop_off_date': '',
      'drop_off_time': "0:00",
      'pick_up_date': '',
      'pick_up_time': "0:00",
      'phone_no': '',
      'discount_code': '',
    }

    formData.drop_off_date = moment(date.setDate(date.getDate() + 1)).format('YYYY-MM-DD')
    formData.pick_up_date = moment(date.setDate(date.getDate() + 7)).format('YYYY-MM-DD')

    this.drop_off_date = this.formatSliderDate(formData.drop_off_date)
    this.pick_up_date = this.formatSliderDate(formData.pick_up_date)
    try {
      this.axios.post(this.apiURL + '/booking/getCheapRates', formData).then((response) => {
        this.companies = response.data.data
      })
      // Navigate to the success route

    } catch (error) {
      // Navigate to the error route

    }

    localStorage.removeItem('booking');
    localStorage.removeItem('form_search');
    localStorage.removeItem('customer');
    localStorage.removeItem('flight');
    this.$refs.loader.style.display = "none"
  }
}
</script>

<style scoped>

</style>