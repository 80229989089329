<template>

  <app-header/>
  <router-view />
  <app-footer/>
  <app-popup/>
  
</template>

<script>

import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import AppPopup from "@/components/AppPopup";

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    AppPopup
  },
  metaInfo: {
    title: 'CPP',
        meta: [
      { name: 'description', content: 'My page description' },
      { name: 'keywords', content: 'vue, meta, example' }
    ],
        link: [
      { rel: 'icon', type: 'image/png', href: '/favicon.png' }
    ]
  }

}
</script>

<style>

</style>
