import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import axios from 'axios'
import VueAxios from 'vue-axios'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import "./assets/css/style.min.css";

import Bowser from "bowser";
import { vMaska } from "maska"


import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import moment from 'moment'

import VueMeta from 'vue-meta'

import "./assets/css/responsive.min.css";

const app = createApp(App);

/* LIVE */
app.config.globalProperties.apiURL = "https://app.compareparkingprices.co.uk/api/index.php"

app.config.globalProperties.imageURL = "https://app.compareparkingprices.co.uk/images/upload/"

app.config.globalProperties.appURL = "https://app.compareparkingprices.co.uk/"

app.config.globalProperties.siteURL = "https://www.compareparkingprices.co.uk/"

// siteKeyG == Google Recaptcha Site Key
// app.config.globalProperties.siteKeyG = '6Le7VO8jAAAAAJvc53DXmNi1ukJ7yfd2DJdhyGzZ'

app.config.globalProperties.siteKeyG = '6LdfdFkkAAAAAIApoQPfxJjdUYkwAA-eGNHmxDb1'

app.config.globalProperties.blogURL = "https://www.blog.compareparkingprices.co.uk/"

app.config.globalProperties.is_stripe = 1;

app.config.globalProperties.is_paypal = 1;

/* demo */
/*app.config.globalProperties.apiURL = "https://cpp-api.mobitsolutions.co.uk/api/index.php"

app.config.globalProperties.imageURL = "https://cpp-api.mobitsolutions.co.uk/images/upload/"

app.config.globalProperties.appURL = "https://cpp-api.mobitsolutions.co.uk/"

app.config.globalProperties.siteURL = "https://www.cpp.mobitsolutions.co.uk/"*/

// app.config.globalProperties.siteKeyG = '6Lep9-IjAAAAAJ6K9BJJVnM6JXLUcZOodLRY6j_g'

app.use(router)
    .use(VueMeta)
    .use(VueAxios, axios)
    .use(moment)
    .directive("maska", vMaska)
    .use(Bowser)
    .component('Datepicker', Datepicker)
    // .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
